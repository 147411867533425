import { PermissionAction } from '@aurion/shared-functions/build/auth/permissions/uiConfig.types';
import ModuleErrorBar from '@aurion/storybook-ui/components/ModuleErrorBar';
import { PropsWithChildren } from 'react';
import { useAccessControl } from '../../lib/auth/permissions/useAccessControl';
import PageContainer from './PageContainer';

export function AccessControl({
    type,
    isPublic = false,
    // the user must have at least one of the provided permissions
    requiredPermissions = [],
    renderNotAuthorised,
    children = <></>,
}: AccessControlProps): JSX.Element {
    const { hasPermission } = useAccessControl();

    if (isPublic || hasPermission(requiredPermissions)) {
        return <>{children}</>;
    }

    if (type === 'page') {
        return (
            <PageContainer navView="home" skipBodyWrap>
                {renderNotAuthorised?.(requiredPermissions) ?? <NotAuthorised type={type} />}
            </PageContainer>
        );
    }

    return renderNotAuthorised?.(requiredPermissions) ?? <NotAuthorised type={type} />;
}

type AccessControlProps = PropsWithChildren<{
    requiredPermissions?: PermissionAction[];
    type: 'page' | 'component';
    isPublic?: boolean;
    renderNotAuthorised?: (requiredPermissions: PermissionAction[]) => JSX.Element;
}>;

function NotAuthorised({ type }: { type: AccessControlProps['type'] }) {
    return (
        <ModuleErrorBar
            errorMessage={`You do not have permission to view this ${
                type === 'page' ? 'page' : 'resource'
            }`}
        />
    );
}
