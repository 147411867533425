import { faDiamondExclamation, faInfo } from '@fortawesome/pro-light-svg-icons';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from './Icon';

export default function ModuleErrorBar(props: ModuleErrorBarProps): JSX.Element {
    const { errorMessage, type } = props;

    return (
        <Box
            px={{ xs: 0, sm: 5 }}
            sx={{
                backgroundColor: (theme) =>
                    type === 'info' ? theme.palette.info.light : theme.palette.error.light,
                minHeight: '48px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    flexShrink: 0,
                    width: '48px',
                    height: '48px',
                    backgroundColor: (theme) =>
                        type === 'info' ? theme.palette.info.main : theme.palette.error.main,
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignSelf: 'start', // align top (when the text is multiline)
                }}
            >
                <Icon icon={type === 'info' ? faInfo : faDiamondExclamation} />
            </Box>
            <Box pl={2} py={1}>
                <Typography className="error-message" variant="body1">
                    {errorMessage}
                </Typography>
            </Box>
        </Box>
    );
}

export type ModuleErrorBarProps = { errorMessage: string; type?: 'error' | 'info' };
