import { useRouter } from 'next/router';
import { AccessControl } from '../components/other/AccessControl';
import { useSession } from '../hooks/useSession';
import { User } from '../lib/auth/auth.types';

export default function DashboardRedirector(): JSX.Element {
    const { session } = useSession();
    const router = useRouter();

    if (!session.isAuthenticated) {
        // Render nothing until authenticated.
        // Leave the control flow of authenticating to _app.page.tsx.
        return <></>;
    }

    const portals = getEffectivePortals(session.user);

    if (portals.includes('admin')) {
        // If user is an administrator, then default to that rather than the user's "my" page.
        router.push('/admin/');
        return <></>;
    }
    if (portals.includes('global')) {
        router.push('/aurionadmin/');
        return <></>;
    }
    if (portals.includes('manager')) {
        router.push('/team/');
        return <></>;
    }
    if (portals.includes('employee')) {
        // If this user is yet to be onboarded, then don't show other pages (such as payroll/employee)
        router.push('/me/');
        return <></>;
    }

    // Render the default unauthorised page
    return <AccessControl type="page" />;
}

/**
 * If the logged-in user doesn't have access to the Admin portal (because isMfaLogin == false)
 * then default to their next best portal (unless that's the only portal they have).
 */
function getEffectivePortals(user?: User): User['profiles'] {
    if (!user) return [];

    const { portals } = user;

    const filteredPortals = user?.isMfaLogin
        ? portals
        : portals.filter((portal) => portal !== 'admin');

    return filteredPortals.length ? filteredPortals : portals;
}
